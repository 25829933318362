import React, { useState, useEffect, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faKey,
  faPenToSquare,
  faPlus,
  faWrench,
} from "@fortawesome/free-solid-svg-icons";
import {
  Card as CardAntd,
  Input,
  Tooltip,
  Select,
  Button,
  Table,
  Space,
  Menu,
  Dropdown,
} from "antd";
import Swal from "sweetalert2";
import userSvc from "../services/userSvc";
import customerSvc from "../services/customerSvc";
import { useHistory } from "react-router-dom";
import {
  showErrorAlert,
  showLoadingAlert,
  showSuccessAlert,
} from "../utils/SwalUtil";
import NavTabs from "../components/NavTabs";
import { ColumnUser } from "../template/TemplateColumn";
import { SearchOutlined } from "@ant-design/icons";
import { getLoginData } from "../services/authSvc";
import DataTable from "../components/template/DataTable";

const { Option } = Select;

const User = () => {
  const searchInputRef = useRef(null);
  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");
  const [selectedRole, setSelectedRole] = useState("");
  const [customers, setCustomers] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const history = useHistory();
  const { user } = getLoginData();
  const role = user?.role;
  const dev = process.env.REACT_APP_UI_DEV;

  useEffect(() => {
    const fetchCustomers = async () => {
      if (role === "admin") {
        const resp = await customerSvc.getCustomer({
          params: { page: 0, limit: 1000 },
        });
        setCustomers(resp?.status === 200 ? resp.data.data : []);
      }
    };
    fetchCustomers();
  }, [role]);

  const fetchUsers = async () => {
    setIsLoading(true);
    const resp = await userSvc.getUser({
      params: {
        page,
        limit: pageSize,
        query: searchTerm,
        active: selectedStatus,
        role: selectedRole,
        id_pelanggan: selectedCustomer,
      },
    });
    if (resp.status === 200) {
      setUsers(resp.data.data);
      setTotalRows(resp.data.totalRows);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchUsers();
  }, [selectedCustomer, selectedRole, selectedStatus, page, pageSize]);

  const isFirstRender = useRef(true);
  useEffect(() => {
    if (!isFirstRender.current) {
      let timerId;
      const fetchData = () => {
        fetchUsers();
      };
      const delayedFetch = () => {
        clearTimeout(timerId);
        timerId = setTimeout(fetchData, 500);
      };
      delayedFetch();
      return () => {
        clearTimeout(timerId);
      };
    } else {
      isFirstRender.current = false;
    }
  }, [searchTerm]);

  const handleClearFilters = () => {
    setSearchTerm("");
    setSelectedRole("");
    setSelectedCustomer(null);
    setSelectedStatus("");
    searchInputRef.current.value = "";
    fetchUsers();
  };

  const handleAction = async (id, type) => {
    const messages = {
      active: {
        title: "Konfirmasi Perubahan?",
        text: "Status Pengguna akan berubah setelah update!",
      },
      resetPassword: {
        title: "Reset Password",
        text: "Apakah anda yakin untuk mengatur ulang kata sandi pengguna ini?",
      },
    };

    const confirmAction = await Swal.fire({
      title: messages[type].title,
      text: messages[type].text,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Lanjutkan!",
      cancelButtonText: "Batalkan",
    });

    if (confirmAction.isConfirmed) {
      try {
        showLoadingAlert("Tunggu", "Memproses...");
        if (type === "active") await userSvc.updateActivate(id);
        if (type === "resetPassword") await userSvc.updatePasswordByEmail(id);
        fetchUsers();
        showSuccessAlert("Berhasil", "Aksi berhasil dilakukan");
      } catch (error) {
        showErrorAlert(
          "Gagal",
          error.response?.data?.message || "Terjadi kesalahan"
        );
      }
    }
  };

  const handleAddUser = () => history.push("/user/add");
  const handleEditUser = (id) => history.push(`/user/update/${id}`);

  const pagination = {
    showTotal: (total, range) =>
      `Showing ${range[0]}-${range[1]} of ${total} items`,
    pageSize,
    total: totalRows,
    onChange: (current) => setPage(current - 1),
    onShowSizeChange: (current, size) => {
      setPageSize(size);
      setPage(0);
    },
    pageSizeOptions: [5, 10, 20],
    showSizeChanger: true,
  };

  const columns = ColumnUser(
    history,
    handleEditUser,
    (id) => handleAction(id, "resetPassword"),
    (id) => handleAction(id, "active"),
    role,
    dev
  );

  const handleTabChange = () => {
    useHistory.push("/");
  };

  return (
    <div className="align-items-center py-4 w-100">
      <CardAntd>
        <NavTabs
          activeTab={"/user"}
          onTabChange={handleTabChange}
          sideTab={"user"}
        />
        {/* <CardAntd
          title={<span style={{ fontSize: "20px" }}>Pengguna</span>}
          extra={
            role === "admin" &&
            dev === "developer" && (
              <Tooltip title="Tambah Pengguna">
                <Button onClick={handleAddUser}>
                  <FontAwesomeIcon icon={faPlus} />
                </Button>
              </Tooltip>
            )
          }
        > */}
        <hr />
        {role === "admin" && dev === "developer" && (
          // <Tooltip title="Tambah Pengguna" className="">
          <Button onClick={handleAddUser} className="mb-3 " type="primary">
            <FontAwesomeIcon icon={faPlus} />
            Tambah Pengguna
          </Button>
          // </Tooltip>
        )}

        <div className="d-flex flex-wrap justify-content-between mb-3 gap-2">
          <Input.Group style={{ flexGrow: 1, flexBasis: "300px" }}>
            {" "}
            <Input
              size="middle"
              placeholder="Cari nama, no hp, email pengguna..."
              prefix={<SearchOutlined />}
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              ref={searchInputRef}
            />
          </Input.Group>
          <Input.Group style={{ flexGrow: 1, flexBasis: "300px" }}>
            <Select
              value={selectedStatus}
              onChange={setSelectedStatus}
              placeholder="Semua Status"
              style={{ width: "100%" }}
            >
              <Option value="">Semua Status</Option>
              <Option value="true">Aktif</Option>
              <Option value="false">Nonaktif</Option>
            </Select>
          </Input.Group>
          <Input.Group style={{ flexGrow: 1, flexBasis: "300px" }}>
            {" "}
            <Select
              value={selectedRole}
              onChange={setSelectedRole}
              placeholder="Semua Role"
              style={{ width: "100%" }}
            >
              <Option value="">Semua Role</Option>
              <Option value="admin">Admin</Option>
              <Option value="admin cabang">Admin Cabang</Option>
              <Option value="operator">Operator</Option>
            </Select>
          </Input.Group>
          <Input.Group style={{ flexGrow: 1, flexBasis: "300px" }}>
            {role === "admin" && (
              <Select
                placeholder="Semua Kantor Cabang"
                allowClear
                showSearch
                onChange={setSelectedCustomer}
                value={selectedCustomer}
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
                style={{ width: "100%" }}
              >
                {customers.map(({ id_pelanggan, name }) => (
                  <Option key={id_pelanggan} value={id_pelanggan}>
                    {name}
                  </Option>
                ))}
              </Select>
            )}
          </Input.Group>

          <Button onClick={handleClearFilters}>Reset Filter</Button>
        </div>
        <DataTable
          columns={columns}
          dataSource={users}
          loading={isLoading}
          pagination={pagination}
        />

        {/* </CardAntd> */}
      </CardAntd>
    </div>
  );
};

export default User;

import React from "react";
import { Select, Spin } from "antd";

const AsyncSelect = ({
  placeholder,
  allowClear = true,
  showSearch = true,
  onDropdownVisibleChange,
  onChange,
  value,
  options,
  loading,
  filterOption = (input, option) =>
    option.children.toLowerCase().includes(input.toLowerCase()),
  notFoundContent = "No Data Found",
  style = { width: "100%", height: "100%" },
  size = "middle",
}) => {
  return (
    <Select
      placeholder={placeholder}
      allowClear={allowClear}
      showSearch={showSearch}
      onDropdownVisibleChange={onDropdownVisibleChange}
      onChange={onChange}
      value={value}
      filterOption={filterOption}
      style={style}
      size={size}
      notFoundContent={loading ? <Spin size="small" /> : notFoundContent}
    >
      {options.map((data) => (
        <Select.Option key={data.key} value={data.value}>
          {data.label}
        </Select.Option>
      ))}
    </Select>
  );
};

export default AsyncSelect;

import React, { useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

import {
  Card as CardAntd,
  Input,
  Tooltip,
  Select,
  Button,
  Tag,
  Table,
  Space,
  Menu,
  Dropdown,
  Badge,
} from "antd";

import { useEffect } from "react";
import Swal from "sweetalert2";

import userSvc from "../services/userSvc";

import customerSvc from "../services/customerSvc";
import userAssetSvc from "../services/userAssetSvc";
import { useHistory } from "react-router-dom";
import {
  showErrorAlert,
  showLoadingAlert,
  showSuccessAlert,
} from "../utils/SwalUtil";
import { ColumnUserAsset } from "../template/TemplateColumn";
import { SearchOutlined } from "@ant-design/icons";
import DataTable from "../components/template/DataTable";

// eslint-disable-next-line import/no-anonymous-default-export

export default () => {
  const searchInputRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchTermUser, setSearchTermUser] = useState("");
  const [userAsset, setUserAsset] = useState([]);

  const history = useHistory();

  const fetchUserAssets = async () => {
    setIsLoading(true);
    const resp = await userAssetSvc.getUserAssets({
      params: {
        page: page,
        limit: pageSize,
        query: searchTerm ? searchTerm : null,
        query_user: searchTermUser ? searchTermUser : null,
      },
    });
    if (resp.status === 200) {
      setUserAsset(resp.data.data);
      setTotalRows(resp.data.totalRows);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchUserAssets();
  }, [page, pageSize]);

  const isFirstRender = useRef(true);
  useEffect(() => {
    if (!isFirstRender.current) {
      let timerId;
      const fetchData = () => {
        fetchUserAssets();
      };
      const delayedFetch = () => {
        clearTimeout(timerId);
        timerId = setTimeout(fetchData, 500); // Adjust the delay time as per your needs
      };
      delayedFetch(); // Call the delayedFetch initially to trigger the initial API call
      return () => {
        // Clean up by clearing the timer when the component unmounts
        clearTimeout(timerId);
      };
    } else {
      isFirstRender.current = false;
    }
  }, [searchTerm, searchTermUser]);

  const handlePageChange = (page) => {
    console.log({ page });
    setPage(page - 1);
  };

  const handlePageSizeChange = (current, pageSize) => {
    setPage(0);
    setPageSize(pageSize);
  };

  const pagination = {
    showTotal: (total, range) =>
      `Showing ${range[0]}-${range[1]} of ${total} items`,
    pageSize: pageSize,
    total: totalRows,
    onChange: handlePageChange,
    onShowSizeChange: handlePageSizeChange,
    pageSizeOptions: [5, 10, 20],
    showSizeChanger: true,
    position: ["bottomRight"],
    className: "custom-pagination",
  };

  const handleDelete = async (id) => {
    const willDelete = await Swal.fire({
      title: "Apakah anda yakin?",
      text: "Anda akan menghapus data user aset ini!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes!",
      cancelButtonText: "Cancel",
    });

    if (willDelete.isConfirmed) {
      try {
        showLoadingAlert("Tunggu", "Menghapus data user aset...");
        const response = await userAssetSvc.deleteUserAsset(id);
        fetchUserAssets();
        showSuccessAlert("Berhasil", "Data user aset berhasil dihapus");
      } catch (error) {
        let message =
          error.response?.data?.message || "Gagal menghapus data user aset";
        showErrorAlert("Gagal", message);
      }
    }
  };

  const handleAddUserAsset = () => {
    history.push("/user-asset/add");
  };

  const handleEditUser = async (user) => {
    history.push(`/user-asset/${user}`);
  };

  const handleManageUser = async (user) => {
    history.push(`/user-asset/users/${user}`);
  };

  const columns = ColumnUserAsset(
    history,
    handleEditUser,
    handleDelete,
    handleManageUser
  );

  return (
    <div className="align-items-center py-4 w-100">
      <CardAntd
        title={<span style={{ fontSize: "20px" }}>User Asset</span>}
        extra={
          <div className="d-flex">
            <div className="d-flex gap-2 ">
              <Tooltip title={"Tambah Role ATM"}>
                <Button
                  variant="success"
                  size="sm"
                  onClick={handleAddUserAsset}
                >
                  <FontAwesomeIcon icon={faPlus} style={{ fontSize: "14px" }} />
                </Button>
              </Tooltip>
            </div>
          </div>
        }
        style={{ width: "100%" }}
      >
        <div className="d-flex flex-wrap justify-content-between mb-3 gap-2">
          <Input.Group style={{ flexGrow: 1, flexBasis: "300px" }}>
            <Input
              size="middle"
              placeholder="Cari nama cabang..."
              prefix={<SearchOutlined />}
              value={searchTerm}
              onChange={(e) => {
                setSearchTerm(e.target.value);
              }}
              className="me-1"
              ref={searchInputRef}
            />
          </Input.Group>
          <Input.Group style={{ flexGrow: 1, flexBasis: "300px" }}>
            <Input
              size="middle"
              placeholder="Cari nama user..."
              prefix={<SearchOutlined />}
              value={searchTermUser}
              onChange={(e) => {
                setSearchTermUser(e.target.value);
              }}
              className="me-1"
              ref={searchInputRef}
            />
          </Input.Group>
        </div>

        <DataTable
          columns={columns}
          dataSource={userAsset}
          loading={isLoading}
          pagination={pagination}
        />
      </CardAntd>
    </div>
  );
};

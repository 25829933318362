import React from "react";
import { Tabs } from "antd";
import { useHistory } from "react-router-dom";

const { TabPane } = Tabs;

const tabFactory = {
  infografis: [
    { label: "Report Summary", path: "/report-summary" },
    { label: "Asset Summary", path: "/asset-summary" },
    { label: "Operator Summary", path: "/operator-summary" },
  ],
  user: [
    { label: "Pengguna", path: "/user" },
    { label: "History Pengguna", path: "/user/history" },
  ],
  operator: [
    { label: "Cari ATM", path: "/operator/laporan" },
    { label: "Riwayat Laporan", path: "/operator/laporan/view" },
  ],
};

const NavTabs = ({ activeTab, sideTab }) => {
  const history = useHistory();
  const tabs = tabFactory[sideTab] || [];

  const handleTabChange = (key) => {
    history.push(key);
  };

  return (
    <div>
      <Tabs
        activeKey={activeTab}
        onChange={handleTabChange}
        type="card"
        tabBarStyle={{ marginBottom: "0px" }}
      >
        {tabs.map((tab) => (
          <TabPane tab={tab.label} key={tab.path} />
        ))}
      </Tabs>
    </div>
  );
};

export default NavTabs;

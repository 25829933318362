import React from "react";
import { Table, Pagination } from "antd";

const DataTable = ({ columns, dataSource, loading, pagination, height }) => {
  return (
    <div>
      <div
        style={{
          overflowX: "auto",
          overflowY: "auto",
          height: height || "calc(100vh - 23rem)",
        }}
        className="custom-scrollbar"
      >
        <Table
          columns={columns}
          dataSource={dataSource}
          loading={loading}
          size="middle"
          pagination={false}
        />
      </div>
      <div className="d-flex justify-content-end pt-3">
        <Pagination {...pagination} />
      </div>
    </div>
  );
};

export default DataTable;

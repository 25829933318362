import React from "react";
import moment from "moment-timezone";
import { Layout, Row, Col } from "antd";
import { GithubOutlined, GlobalOutlined } from "@ant-design/icons";

const { Footer } = Layout;

const CustomFooter = () => {
  const currentYear = moment().get("year");

  return (
    <Footer
      style={{
        backgroundColor: "transparent", // Light background
        // borderTop: "1px solid #e9ecef",
        padding: "0.5rem 2rem 1rem ",
        textAlign: "center",
      }}
    >
      <Row justify="center" align="middle" gutter={[16, 8]}>
        <Col>
          <a
            href="https://grahamitraempatenam.com"
            target="_blank"
            rel="noopener noreferrer"
            style={{
              fontWeight: "500",
              color: "#6c757d",
              textDecoration: "none",
              fontSize: "14px",
            }}
          >
            <GlobalOutlined style={{ marginRight: "8px", fontSize: "16px" }} />
            JWA Software © {currentYear}
          </a>
        </Col>
      </Row>
      {/* <Row
        justify="center"
        align="middle"
        gutter={[16, 8]}
        style={{ marginTop: "10px" }}
      >
        <Col>
          <a
            href="https://github.com/jwa-software"
            target="_blank"
            rel="noopener noreferrer"
            style={{
              fontWeight: "500",
              color: "#6c757d",
              textDecoration: "none",
              fontSize: "14px",
            }}
          >
            <GithubOutlined style={{ marginRight: "8px", fontSize: "16px" }} />
            Visit our GitHub
          </a>
        </Col>
      </Row> */}
    </Footer>
  );
};

export default CustomFooter;
